<template>
  <div class="px-8 mt-4">
    <v-dialog
      v-model="dialogModel"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="poModal"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="green darken-2" max-height="65">
          <v-toolbar-title> {{ rspiData.RSPICODE }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div>
          <v-stepper alt-labels>
            <v-stepper-header>
              <v-stepper-step
                color="green"
                step="1"
                class="pa-3 pl-md-16"
                :complete="true"
              >
              <span class="text-no-wrap">Suppy Creation</span>
              <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(rspiData.DateCreated)}}</small>
              </v-stepper-step>

              <v-divider class="my-6"></v-divider>

              <v-stepper-step
                color="green"
                :complete="rspiData.VerifiedStatusID == 7"
                :rules="[() => rspiData.VeriifiedStatusID != 6]"
                step="2"
                class="pa-3"
              >
                <span class="text-no-wrap">Accounting Verification</span>
                <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(rspiData.VerifiedDate)}}</small>
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </div>
        <v-alert
          border="left"
          type="error"
          v-if="rspiData.VerifiedStatusID == 6"
          prominent
          dense
          class="white--text amber accent-4"
        >
          <div v-if="rspiData.VerifiedRemarks"> <strong>Accounting:</strong> {{ rspiData.VerifiedRemarks }}</div>
        </v-alert>
        <v-alert
          border="left"
          type="error"
          v-if="rspiData.IsCancelled"
          prominent
          dense
          class="white--text amber accent-4"
        >
          <strong>CANCELLED</strong><br />
          <small>Date Cancelled: {{formatDateTime(rspiData.DateCancelled)}}</small>
        </v-alert>
        <v-card-text style="max-height: 900px">
          <div>
            <v-row class="mt-4">
              <v-col cols="12" class="d-flex justify-center">
                <v-subheader class="font-weight-bold text-h6"
                  >REPORT OF SEMI-EXPENDABLE PROPERTY ISSUED</v-subheader
                >
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-5">
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Entity Name:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>Davao del Norte State College</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Fund Code:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ rspiData.FundYear }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">RSPI No.:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ rspiData.RSPICODE }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Date Created:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ formatDate(rspiData.DateCreated) }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Description:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="9">
              <v-subheader>{{ rspiData.Description }}</v-subheader>
            </v-col>
          </v-row>
          </div>
          <v-row>
            <v-col lg="12" class="">
              <v-data-table
                :headers="headers"
                :items="itemList"
                item-key="ICSID"
                class="elevation-1 mt-4"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <span class="green--text text--darken-2"
                      >List Of ICS</span
                    >
                    <v-spacer></v-spacer>
                    <span v-if="rspiData.IsCancelled == 0">
                    <v-btn
                      outlined
                      color="grey darken-2 mr-2"
                      dark
                      small
                      @click="editRSPI()"
                      v-if="$store.state.user.roleID == 6"
                      class="mr-2"
                    >
                      <v-icon>mdi-pencil-outline</v-icon>
                      Edit RSPI
                    </v-btn>
                    <v-btn
                      outlined
                      color="red darken-2"
                      dark
                      small
                      @click="print(rspiData)"
                      class="mr-2"
                    >
                      <v-icon>print</v-icon>
                      Print
                    </v-btn>
                    <v-btn
                      color="red darken-2"
                      class="white--text"
                      small
                      v-if="$store.state.user.roleID == 6 && itemList.length > 0"
                      @click="unpublish()"
                    >
                      Unpublish
                    </v-btn>
                    </span>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.IssuedDate`]="{ item }">
                  <span class="text-no-wrap">{{ formatDate(item.IssuedDate)}}</span>
                </template>
                <template v-slot:[`item.Action`]="{ item }">
                  <v-btn color="header" x-small outlined @click="viewICS(item)">View</v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogUnpublish" max-width="500px">
          <v-card>
            <v-card-title class="headline pt-5">Confirmation</v-card-title>
            <div class="mx-6">
              Are you sure you want to unpublish this Report of Semi-Expendable Property Issued?
            </div>
            <v-card-actions class="mt-5">
              <v-spacer></v-spacer>
              <v-btn color="grey darken-2" outlined @click="dialogUnpublish = false">Cancel</v-btn>
              <v-btn color="red darken-2 white--text" @click="confirmUnpublish()"><v-icon>mdi-delete</v-icon> Unpublish</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    <ICSModal :icsData="icsData" />
    <EditRSPIModal :rspiData="editData" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>
    
    <script>
export default {
  components: {
    ICSModal: () => import("./ViewICS.vue"),
    EditRSPIModal: () => import("./CreateRSPI.vue"),
  },
  props: {
    rspiData: {},
  },
  data: () => ({
    dialog: false,
    users: [],
    dialogModel: false,
    PurposeDialog: false,
    supplier: [],
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    menu5: false,
    menu6: false,
    dialogIARVersion: false,
    link: "",
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
    headers: [
      {
        text: "ICS Code",
        align: "start",
        sortable: false,
        value: "ICSCODE",
      },
      {
        text: "RIS Code",
        align: "start",
        sortable: false,
        value: "RISCODE",
      },
      {
        text: "Purpose",
        align: "start",
        sortable: false,
        value: "ICSPurpose",
      },
      // {
      //   text: "Fund Code",
      //   align: "center",
      //   sortable: false,
      //   value: "FundCode",
      // },
      {
        text: "Date Issued",
        value: "IssuedDate",
        align: "center",
        sortable: false,
      },
      { text: "Action", value: "Action", align: "center", sortable: false },
    ],
    itemList: [],
    submitbtn: false,
    dialogConfirmSubmit: false,
    dialogUnpublish: false,
    isLoading: false,
    icsData: [],
    editData: [],
  }),
  watch: {
    rspiData: {
      handler(data) {
        if (data.RSPIID) {
          this.getICSList();
          this.dialogModel = true;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.eventHub.$on("closeViewICS", () => {
      this.getICSList();
    });
    this.eventHub.$on("closeCreateRSPI", () => {
      this.getICSList();
    });
  },
  beforeDestroy() {
    this.eventHub.$off("closeViewICS");
    this.eventHub.$off("closeCreateRSPI");
  },
  methods: {
    print() {
      window.open(this.apiUrl + "/rspi/report/" + this.rspiData.RSPIID);
    },
    closeDialog() {
      this.itemList = [];
      this.dialogModel = false;
      this.eventHub.$emit("closeViewRSPI", false);
    },
    getICSList() {
      this.itemList = [];
      this.loading = true;
      let data = new FormData();
      data.append("RSPIID", this.rspiData.RSPIID);
      this.axiosCall("/rspi/getICSList", "POST", data).then((res) => {
        this.itemList = res.data.data;
        this.loading = false;
      });
    },
    viewICS(item){
      this.icsData = item;
    },
    editRSPI(){
      this.editData = {...this.rspiData, edit: true, items: this.itemList};
    },
    unpublish(){
      this.dialogUnpublish = true;
    },
    confirmUnpublish(){
      let data = new FormData();
      data.append("RSPIID", this.rspiData.RSPIID);
      this.axiosCall("/rspi/unpublish", "POST", data).then((res) => {
        if(res.data.status){
        this.closeDialog();
          this.dialogUnpublish = false;
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "success";
          this.fadeAwayMessage.message = "RSPI was successfully unpublished";
          this.fadeAwayMessage.header = "System Message";
        }
        else{
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.message = "Something went wrong!";
          this.fadeAwayMessage.header = "System Message";
        }
      });
    }
  },
};
</script>
        
    <style scoped>
.v-dialog--fullscreen,
.poModal {
  overflow-x: hidden !important;
}
</style>